// import { IClientGroupsResponse } from './../api/clients/clients';
import { createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../../models/movement';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { PagListResult } from '../../models/types';
//TODO: check out correct folder structure
export const goMovementApi = createApi({
  reducerPath: 'goOrderMovement',
  baseQuery: getBaseQueryForGoLang,
  refetchOnFocus: true,
  tagTypes: ['orderMovement'],
  endpoints: (builder) => ({
    getMovement: builder.query<PagListResult<I.IMovementItem>, any>({
      query: (params) => ({ url: '/movements/', params }),
    }),
  }),
});

export const { useGetMovementQuery } = goMovementApi;
