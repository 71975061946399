import React, {
  ChangeEvent,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cls from './Select.module.scss';
import './Select.scss';
import { ReactComponent as SelectDropdownArrowDisabled } from 'src/assets/img/icons/select-disabled-arrow.svg';
import { ReactComponent as SelectCloseIcon } from 'src/assets/img/icons/select-close-icon.svg';
import { ReactComponent as SearchNewIcon } from 'src/assets/img/icons/search-new-icon.svg';
import { ReactComponent as SelectDropdownArrowNewIcon } from 'src/assets/img/icons/select-dropdown-arrow-new-icon.svg';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import getDataForTree from 'src/utils/helpers/getDataForTree';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';
import DropdownItemEntry from './DropdownItemEntry';
import { NodeId } from 'react-accessible-treeview';
import ErrorExtra from '../../common/ErrorExtra';
import { errorTextType } from 'src/models/types';
import InfiniteScroll from "react-infinite-scroll-component";
import CRMLoader from "../Loaders/CRMLoader";

export enum SelectControllerTheme {
  TRANSPARENT = 'transparent',
  FROM_TABLE = 'from-table',
  DASHED = 'dashed',
  BOTTOM_BORDERED = 'bottom-bordered',
  BOTTOM_BORDERED_DASHED = 'bottom-bordered-dashed',
  FROM_TABLE_GRADIENT = 'from-table-gradient',
  FROM_TABLE_TRANSPARENT = 'from-table-transparent',
  FULL_BORDERED_SHADOW = 'full-bordered-shadow',
}
export enum SelectDropdownTheme {
  TOP_UNBORDERED = 'top-unbordered',
  FULL_BORDERED = 'full-bordered',
  FULL_BORDERED_NO_RADIUS = 'full-bordered-no-radius',
  FROM_TABLE = 'from-table',
  FULL_BORDERED_SHADOW = 'full-bordered-shadow',
  // SEARCH="search"
}
export type IOptionValue = string | number | null;
export interface IOption {
  title: string | null;
  value: IOptionValue;
  element?: ReactNode;
  isNoValueOption?: boolean;
  isPlaceholder?: boolean;
  bottomBordered?: boolean;
  topBordered?: boolean;
  options?: IOption[];
  isDisabled?: boolean;
  isControllerHidden?: boolean;
  searchValue?: string;
  dropdownOnlyElement?: ReactElement;
  tooltipTitle?: string;
}
export interface SelectProps {
  controllerTheme: SelectControllerTheme;
  dropdownTheme: SelectDropdownTheme;
  options?: IOption[];
  fetchNextPage?: () => void;
  hasMore?: boolean;
  stylesConfig?: {
    isNoFixedArrowPosition?: boolean;
    isGrayPlaceholder?: boolean;
    hideControllerWhenDropdownActive?: boolean;
    searchBottom?: boolean;
    disabledDropdownBackgroundTransparent?: boolean;
    isWordBreak?: boolean;
    isWidth100?: boolean;
    arrowIconHidden?: boolean;
    selectedArrowHidden?: boolean;
  };
  functionalConfig?: {
    clearButton?: boolean;
  };
  recursiveSelect?: {
    titleFieldName: string;
    idFieldName?: string;
    data?: any;
  };
  controllerClassName?: string;
  className?: string;
  errorText?: errorTextType;
  hasSearch?: boolean;
  onSearch?: (value: string) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  onChange?: (value: any, event?: React.MouseEvent) => void;
  customIconArrow?: ReactNode;
  customIconSearch?: ReactNode;
  register?: UseFormRegisterReturn;
  setValueInForm?: UseFormSetValue<any>;
  defaultValue?: IOptionValue;
  propsValue?: string | number | null | string[] | number[];
  hideSelectedItem?: boolean;
  shortTitleMultiSelect?: boolean;
  disablePortal?: boolean;
  isNoValueAllText?: boolean;
  controllerIcon?: ReactNode;
  disableSearchLimit?: boolean;
}
export const Select: FC<SelectProps> = ({
  controllerTheme = SelectControllerTheme.TRANSPARENT,
  customIconSearch,
  dropdownTheme = SelectDropdownTheme.TOP_UNBORDERED,
  options = [],
  customIconArrow,
  defaultValue = null,
  stylesConfig,
  controllerClassName,
  hasSearch,
  onSearch,
  disabled,
  onChange,
  className,
  functionalConfig,
  isMultiSelect,
  shortTitleMultiSelect,
  register,
  setValueInForm,
  propsValue,
  recursiveSelect,
  hideSelectedItem,
  disablePortal = true,
  isNoValueAllText,
  controllerIcon,
  errorText,
  fetchNextPage,
  hasMore = false,
  disableSearchLimit = false
}) => {
  const { t: tg } = useTranslation('translation');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState<
    IOptionValue | undefined
  >();
  const [selectedOptionAsync, setSelectedOptionAsync] = useState<IOption>();
  useEffect(() => {
    if (defaultValue && !selectedValue && register && setValueInForm) {
      setSelectedValue(defaultValue);
      setValueInForm(register.name, defaultValue);
    }
  }, [defaultValue, selectedValue, register, setValueInForm]);
  const [selectedValueArray, setSelectedValueArray] = useState<IOptionValue[]>(
    []
  );
  const [selectedOptionsAsyncArray, setSelectedOptionsAsyncArray] = useState<IOption[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');

  const multiselectRemoveHandler = useCallback(
    (value: IOptionValue, event?: React.MouseEvent) => {
      if (isMultiSelect) {
        event?.stopPropagation();
        const res = selectedValueArray.filter((item) => item != value);
        setSelectedValueArray(res);
        handleClose();
        if (onChange) {
          onChange(res, event);
        }
        if (setValueInForm && register) {
          setValueInForm(register?.name, res);
        }
      }
    },
    [
      onChange,
      register,
      setValueInForm,
      setSelectedValueArray,
      selectedValueArray,
    ]
  );

  const [openedOptions, setOpenedOptions] = useState<IOptionValue[]>([]);
  const toggleOpenedOptions = (id: number, event: React.MouseEvent) => {
    event.stopPropagation();
    const findedIndex = openedOptions.findIndex((item) => item === id);
    if (findedIndex === -1) {
      const copy = [...openedOptions];
      copy.push(id);
      setOpenedOptions(copy);
    } else {
      const copy = [...openedOptions];
      copy.splice(findedIndex, 1);
      setOpenedOptions(copy);
    }
  };
  useEffect(() => {
    if (recursiveSelect && recursiveSelect.data && propsValue) {
      const preparedData = getDataForTree(recursiveSelect.data);
      const findedItem = preparedData.find((item) => item.id == propsValue);
      const findParents = (
        array: IOptionValue[],
        parent: NodeId
      ): IOptionValue[] => {
        const findedParent = preparedData.find((item) => item.id == parent);
        if (!findedParent) {
          return array;
        }
        if (findedParent?.parent) {
          return [
            ...array,
            findedParent.id,
            ...findParents(array, findedParent.parent),
          ];
        } else {
          return [...array, findedParent.id];
        }
      };

      if (findedItem?.parent) {
        setOpenedOptions(findParents([], findedItem.parent));
      }
    }
  }, [recursiveSelect, recursiveSelect?.data, propsValue]);
  const mainOptions = useMemo(() => {
    let res: IOption[] = [];
    if (recursiveSelect) {
      if (!recursiveSelect.data) return [];

      const preparedData = getDataForTree(recursiveSelect.data);

      const handleData = (item: any, level: number) => {
        if (level !== -1) {
          const data: any = {};

          if (recursiveSelect.idFieldName) {
            data[recursiveSelect.idFieldName[0]] =
              item.metadata[recursiveSelect.idFieldName[1]];
          }
          data.title = null;
          data.value = item.metadata.id;
          data.element = (
            <div className="align-center user-select-none">
              <div
                className="hidden-part"
                style={{ paddingLeft: `${15 * level}px` }}
              />
              {item.metadata[recursiveSelect.titleFieldName]}
              {item.children?.length ? (
                <span
                  onClick={(event) => {
                    toggleOpenedOptions(data.value, event);
                  }}
                  className="ml-3 hidden-part"
                >
                  ▼
                </span>
              ) : null}
            </div>
          );
          res.push(data);
        }

        if (openedOptions.includes(item.id) || level === -1) {
          item.children.forEach((children: any) => {
            handleData(
              preparedData.find((childrenItem) => childrenItem.id === children),
              level + 1
            );
          });
        }
      };
      handleData(preparedData[0], -1);
    } else {
      res = [...options];
    }
    if ((!res[0] || res[0].value !== null) && !defaultValue) {
      return [
        {
          title: isNoValueAllText ? tg('select-all') : tg('select-no-value'),
          value: null,
        },
        ...res,
      ] as IOption[];
    }
    return res;
  }, [
    recursiveSelect,
    recursiveSelect?.data,
    openedOptions,
    options,
    propsValue,
  ]);
  const selectedOptionMemo = useMemo(() => {
    return mainOptions.find((item) => item.value == selectedValue)
  },[selectedValue, mainOptions])
  const selectValue = useCallback(
    (value: IOptionValue, event?: React.MouseEvent) => {
      let res: IOptionValue | IOptionValue[] = value;
      if (isMultiSelect) {
        res = [...selectedValueArray, value];
        setSelectedValueArray(res);
        handleClose();
        if (onSearch && res){
          const selectedOptionsRes: IOption[] = [];
          [...selectedOptionsAsyncArray, ...mainOptions].forEach( item => {
            if (!res) return;
            //@ts-ignore
            if (!selectedOptionsRes.find(option => option.value == item.value) && res.find(optionValue => optionValue == item.value)){
              selectedOptionsRes.push( item )
            }
          })
          setSelectedOptionsAsyncArray(selectedOptionsRes);
        }
      } else {
        if ((setValueInForm && register?.name) || onChange) {
          res = value || defaultValue;
          setSelectedValue(res);
          if (onSearch){
            setSelectedOptionAsync(mainOptions.find(item => item.value == res))
          }
        }
        handleClose();
      }
      if (
        onChange &&
        (res || res === null) &&
        (propsValue === undefined || propsValue !== value)
      ) {
        onChange(res, event);
      }
      if (setValueInForm && register) {
        setValueInForm(register?.name, res, { shouldValidate: !!event });
      }
    },
    [
      onChange,
      register,
      setValueInForm,
      setSelectedValue,
      setSelectedValueArray,
      selectedValueArray,
      mainOptions,
      onSearch
    ]
  );

  const multiselectSelectedOptions = useMemo(() => {
    if (isMultiSelect && Array.isArray(selectedValueArray)) {
      const res = [...mainOptions];
      selectedOptionsAsyncArray.forEach(item => {
        if (!res.find(option => option.value == item.value)){
          res.push(item)
        }
      })
      return selectedValueArray.map((value) =>
        res.find((option) => option.value == value)
      );
    }
  }, [mainOptions, selectedValueArray, isMultiSelect, selectedOptionsAsyncArray]);

  useEffect(() => {
    if (isMultiSelect || Array.isArray(propsValue)) {
      if (
        Array.isArray(propsValue) &&
        JSON.stringify(propsValue) !== JSON.stringify(selectedValueArray)
      ) {
        setSelectedValueArray(propsValue);
      }
      return;
    }
    if (propsValue === null && propsValue != selectedValue) {
      selectValue(propsValue);
    }
    else if (propsValue && selectedValue != propsValue) {
      selectValue(propsValue);
    } else {
      if (
        (!mainOptions[0]?.value
          ? !propsValue &&
            mainOptions[0]?.value !== propsValue &&
            propsValue !== null && !onSearch
          : false) &&
        !disabled
      ) {
        selectValue(mainOptions[0]?.value);
      }
    }
  }, [propsValue, mainOptions.length, disabled, register, selectValue]);

  const isTopBorderIndex = useMemo(() => {
    if (options.findIndex((item) => item.isNoValueOption) > -1) {
      return {
        index: 1,
        hasBorder: true,
      };
    } else {
      return {
        index: 0,
        hasBorder: false,
      };
    }
  }, [options]);

  const resetSelectValue = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const findedNullOption = options.find((item) => item.isPlaceholder);
    if (findedNullOption) {
      selectValue(findedNullOption.value);
    }
  };

  const filteredOptions = useMemo(() => {
    if (searchValue && !onSearch) {
      return mainOptions.filter((item) => {
        if (item.title) {
          return item.title
            ?.toLowerCase()
            .includes(searchValue.toLocaleLowerCase());
        } else if (item.searchValue) {
          return item.searchValue
            ?.toLowerCase()
            .includes(searchValue.toLocaleLowerCase());
        }
      });
    }
    return mainOptions;
  }, [mainOptions, searchValue, onSearch]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<any>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!onSearch){
      setSearchValue('');
    }
  }, [anchorEl]);
  const searchTimeout = useRef<any>(null)

  const onSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if(hasSearch){
      const value = event.target.value
      setSearchValue(value);
      if (onSearch && (disableSearchLimit || value.length > 2 || !value.length)){
        if(onSearch){
          clearTimeout(searchTimeout.current);
          searchTimeout.current = setTimeout(() => {
            onSearch(value)
          }, 500);
        }
      }
    }
  }
  const arrowIconHidden =
    stylesConfig?.arrowIconHidden ||
    (stylesConfig?.selectedArrowHidden && selectedValue);
  const selectedOption = selectedOptionMemo || selectedOptionAsync;
  return (
    <div
      className={`${cls['select']} select ${className} ${
        stylesConfig?.isWidth100 && cls['controller-w-100']
      }`}
      ref={dropdownRef}
    >
      <div
        className={cls['select-inner-div']}
        aria-describedby={id}
        onClick={handleClick}
      >
        {(isMultiSelect ? !multiselectSelectedOptions?.length : true) && (
          <div
            className={`${
              cls['controller']
            } select__controller dropdown-control ${
              stylesConfig?.isNoFixedArrowPosition &&
              cls['controller-no-fixed-arrow-position']
            } ${cls[controllerTheme]} ${controllerClassName} ${
              selectedOption?.isPlaceholder && cls['controller--placeholder']
            } ${
              disabled &&
              (stylesConfig?.disabledDropdownBackgroundTransparent
                ? cls['controller-disabled-transparent']
                : cls['controller-disabled'])
            } ${stylesConfig?.isWordBreak && cls['controller-word-break']}
            ${stylesConfig?.arrowIconHidden && cls['arrow-icon-hidden']}
          `}
          >
            {controllerIcon && (
              <div className={cls['controller-icon']}>{controllerIcon}</div>
            )}
            {selectedOption?.element && (
              <span
                className={`display-flex ${cls['controller__title']} ${
                  selectedOption.isControllerHidden &&
                  cls['controller__title--hidden']
                } select-controller__title`}
              >
                {selectedOption.element}
              </span>
            )}
            {selectedOption?.title &&
              (isMultiSelect ? !selectedValueArray.length : true) && (
                <span
                  className={`display-flex ${cls['controller__title']} ${
                    selectedOption.isControllerHidden &&
                    cls['controller__title--hidden']
                  } select-controller__title`}
                >
                  {selectedOption?.title}
                </span>
              )}
            {functionalConfig?.clearButton && (
              <SelectCloseIcon
                onClick={resetSelectValue}
                className={`${cls['controller__dropdown-close']} `}
              />
            )}

            {!arrowIconHidden &&
              (customIconArrow ? (
                customIconArrow
              ) : dropdownTheme === SelectDropdownTheme.FULL_BORDERED_SHADOW ? (
                // <SearchNewIcon className={cls['controller__dropdown-arrow-new']}></SearchNewIcon>
                <SelectDropdownArrowNewIcon
                  className={cls['controller__dropdown-arrow-new']}
                />
              ) : (
                <SelectDropdownArrowDisabled
                  className={`${cls['controller__dropdown-arrow']} ${
                    disabled &&
                    stylesConfig?.disabledDropdownBackgroundTransparent &&
                    cls['controller-disabled-transparent-arrow']
                  }`}
                />
              ))}
          </div>
        )}
        {isMultiSelect && !!multiselectSelectedOptions?.length && (
          <div
            className={`${
              isMultiSelect && cls['multiselect__wrapper']
            } multiselect__wrapper
            ${
              disabled &&
              (stylesConfig?.disabledDropdownBackgroundTransparent
                ? cls['controller-disabled-transparent']
                : cls['controller-disabled'])
            }
            `}
          >
            {multiselectSelectedOptions?.map((option) => {
              return (
                <div key={option?.value} className={cls['multiselect__item']}>
                  {option?.element}
                  {shortTitleMultiSelect && (
                    <div className="multiselect__item_title">
                      {option?.title}
                    </div>
                  )}
                  {!shortTitleMultiSelect && option?.title}
                  <button
                    className={cls['remove-button']}
                    onClick={(event) => {
                      multiselectRemoveHandler(option!.value, event);
                    }}
                  >
                    x
                  </button>
                </div>
              );
            })}
          </div>
        )}
        <ErrorExtra errorText={errorText} />
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal={disablePortal}
        PaperProps={{
          className: `select-dropdown-paper ${cls[dropdownTheme]}`,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {/* <Dropdown close={closeDropdown}> */}
        <div
          id="scrollableDiv"
          style={{
            // width: Math.random() < 0.5 ? "80px" : "150px"
            width: dropdownRef.current?.offsetWidth,
          }}
          className={`${cls['dropdown']} ${
            isMultiSelect && cls['dropdown--multiple']
          } dropdown ${cls[dropdownTheme]} ${
            hasSearch ? cls['dropdown__with-search'] : ''
          } ${
            stylesConfig?.searchBottom
              ? cls['dropdown__with-search--bottom']
              : ''
          } ${
            stylesConfig?.hideControllerWhenDropdownActive &&
            cls['dropdown__controller-hide']
          }`}
        >
          {hasSearch && (
            <div
              className={`${
                cls['dropdown__search-wrapper']
              } dropdown__search-wrapper ${
                dropdownTheme === SelectDropdownTheme.FULL_BORDERED_SHADOW &&
                cls['dropdown__search-wrapper-new']
              }`}
            >
              {customIconSearch ? (
                customIconSearch
              ) : dropdownTheme === SelectDropdownTheme.FULL_BORDERED_SHADOW ? (
                <SearchNewIcon className={cls['dropdown__search-icon']} />
              ) : (
                <>
                  <SearchNewIcon
                    className={`${cls['dropdown__search-icon']} ${cls['dropdown__search-icon--main']}`}
                  />
                  {/* <img
                        className={cls['dropdown__search-icon']}
                        src={InputSearchIcon}
                      /> */}
                </>
              )}
              <input
                value={searchValue}
                className={cls['dropdown__search']}
                onChange={onSearchHandler}
              />
            </div>
          )}
          <InfiniteScroll
            dataLength={filteredOptions.length}
            next={fetchNextPage ? fetchNextPage : () => {null}}
            //for test loader design
            // next={() => {null}}
            hasMore={hasMore}
            loader={<div  className={`${cls['dropdown__item-wrapper']} flex-center`}>
              <CRMLoader size={20} />
            </div>}
            scrollableTarget="scrollableDiv"
          >
            {filteredOptions
              .filter(
                (option) =>
                  !option.isPlaceholder &&
                  (hideSelectedItem ? selectedValue !== option.value : true) &&
                  (isMultiSelect
                    ? option.value !== null &&
                      !selectedValueArray.includes(option.value)
                    : true)
              )
              .map((option, optionIndex) => (
                <div
                  key={option.value}
                  className={`${cls['dropdown__item-wrapper']}`}
                >
                  <div
                    key={option.value}
                    className={`dropdown__item ${cls['dropdown__item']} ${
                      isMultiSelect && cls['dropdown__item--multiple']
                    } ${option.isDisabled && cls['dropdown__item--disabled']} ${
                      isTopBorderIndex.hasBorder &&
                      optionIndex === isTopBorderIndex.index &&
                      !hasSearch &&
                      cls['top-border-option']
                    } ${option.bottomBordered && cls['bottom-border-option']} ${
                      option.topBordered && cls['top-border-option']
                    }`}
                    onClick={(event) => {
                      selectValue(option.value, event);
                    }}
                  >
                    {/*filteredOptions передается для того чтобы пересчитать тултип при изменении опций, например поиске*/}
                    {/*пересчитывание нужно когда список стал короче и полоса скролла исчезла, */}
                    {/*и у айтемов появляется больше места на 5px, в некоторых из-за этого пропадает троеточие */}
                    {/*а тултип остается если его не пересчитать, так как считался он когда полоса скролла и троеточие еще были  */}
                    <DropdownItemEntry
                      option={option}
                      open={open}
                      filteredOptions={filteredOptions}
                    />
                  </div>
                  {option.options &&
                    typeof option.value === 'string' &&
                    openedOptions.includes(option.value) &&
                    option.options.map((optionInner) => (
                      <div
                        key={optionInner.value}
                        className={`${cls['dropdown__item']} ${cls['dropdown__item-inner']}`}
                        onClick={() => {
                          selectValue(optionInner.value);
                        }}
                      >
                        {option.element && (
                          <span className={cls['dropdown__item-icon']}>
                            {optionInner.element}
                          </span>
                        )}
                        {option?.title && (
                          <span className={cls['dropdown__item-text']}>
                            {optionInner.title}
                          </span>
                        )}
                      </div>
                    ))}
                </div>
              ))
            }
          </InfiniteScroll>
        </div>
      </Popover>
    </div>
  );
};
