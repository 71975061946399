export enum ProducerTypeEnum {
  COUNTRY = 'COUNTRY',
  BRAND = 'BRAND',
}
export enum UserRoleEnum {
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  MANAGER = 'manager',
}
export enum CurrencyEnum {
  UAH = 'UAH',
  EUR = 'EUR',
  GBR = 'GBR',
  PLN = 'PLN',
  RUB = 'RUB',
  CNH = 'CNH',
  TRY = 'TRY',
  USD = 'USD',
}
export enum EnumLanguage {
  uk = 'en',
  // pl = 'pl',
  ru = 'ru',
  ua = 'uk',
}

export enum EnumNotifications {
  REGULAR = 'REGULAR',
}
export enum EnumStatuses {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  TRASH = 'TRASH',
  REFUND = 'REFUND',
}
export enum EnumSmsStatuses {
  DELIVRD = 'DELIVRD',
  EXPIRED = 'EXPIRED',
  UNDELIV = 'UNDELIV',
  REJECTD = 'REJECTD',
}
export enum EnumTransliteType {
  LATIN = 'latin',
  CYRILLIC = 'cyrillic',
}
export enum EnumDeliveryTypes {
  NOVA_POSHTA = 'NovaPoshta',
  UKR_POSHTA = 'UkrPoshta',
  SELF_DELIVERY = 'SelfDelivery',
  COURIER = 'Courier',
}
export enum EnumCurrenciesTypes {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR'
}
export enum EnumPostTypes {
  NOVA_POSHTA = 'NovaPoshta',
  UKR_POSHTA = 'UkrPoshta',
}

export enum EnumPaymentTypes {
  AtDelivery = 'AtDelivery',
  PrePayment = 'PrePayment',
  Paid = 'Paid',
  Exchange = 'Exchange',
}

export enum EnumServiceTypes {
  Doors = 'Doors',
  Warehouse = 'Warehouse',
}
export enum EnumUPServiceTypes {
  D = 'D',
  W = 'W',
}
export enum EnumUPServiceTypes2 {
  D = 'D2',
  W = 'W2',
}

export const serviceTypeUPAdapter = {
  [EnumServiceTypes.Doors]: 'D',
  [EnumServiceTypes.Warehouse]: 'W',
};
export const serviceTypeUPAdapter2 = {
  [EnumServiceTypes.Doors]: 'D2',
  [EnumServiceTypes.Warehouse]: 'W2',
};
export const serviceTypeUPAdapterRevert = {
  [EnumUPServiceTypes.D]: 'Doors',
  [EnumUPServiceTypes.W]: 'Warehouse',
};

export enum PayerEnum {
  Sender = 'Sender',
  Recipient = 'Recipient',
}
export enum MobileOperators {
  Kyivstar = 'Kyivstar',
  Vodafone = 'Vodafone',
  Lifecell = 'Lifecell',
  TriMob = 'TriMob',
  PEOPLEnet = 'PEOPLEnet',
}
export enum CargoSEnum {
  Parcel = 'Parcel',
  Cargo = 'Cargo',
  Documents = 'Documents',
  TiresWheels = 'TiresWheels',
  Pallet = 'Pallet',
}
export enum PaymentType {
  Cash = 'Cash',
  NonCash = 'NonCash',
}
export enum NPPaymentType {
  Backward = 'Backward',
  NonBackward = 'NonBackward',
}
