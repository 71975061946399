// import { IClientGroupsResponse } from './../api/clients/clients';
import { FetchBaseQueryMeta, createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../api/clients/clients';
import { baseQuery } from '../utils/baseQuery';

export const clientsApi = createApi({
  reducerPath: 'clients',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['Clients', 'ClientGroups'],
  endpoints: (builder) => ({
    getClientGroups: builder.query<I.IClientGroupsResponse, unknown>({
      query: (params: I.IGetClientGroupsParams) => ({
        url: '/clientGroups/',
        params,
      }),
      transformResponse: (
        data: I.IClientGroupsResponse,
        meta: FetchBaseQueryMeta
      ) => ({
        data: data.data,
        status: meta?.response?.status,
      }),
      providesTags: ['ClientGroups'],
    }),

    createClientGroup: builder.mutation<any, I.IRequestCreateClientGroup>({
      query: ({ body }) => ({
        url: '/clientGroups/',
        body,
        method: 'POST',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),
    updateClientGroup: builder.mutation<any, I.IRequestUpdateClientGroup>({
      query: ({ id, body }) => ({
        url: `/clientGroups/${id}`,
        body,
        method: 'PUT',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),
    deleteClientGroup: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/clientGroups/delete',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['ClientGroups', 'Clients'],
    }),

    getClientList: builder.query<I.IClientRepsonse, unknown>({
      query: (params: object) => ({ url: '/clients', params }),
      transformResponse: (
        data: I.IClientRepsonse,
        meta: FetchBaseQueryMeta
      ) => ({
        // data:data.data.map((item, index) => ({...item, id:item.id.toString()})),
        data: data.data,
        status: meta?.response?.status,
      }),
      providesTags: ['ClientGroups', 'Clients'],
    }),

    createClient: builder.mutation<any, I.IRequestCreateClient>({
      query: ({ body }) => ({
        url: '/clients/',
        body,
        method: 'POST',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['Clients'],
    }),
    updateClient: builder.mutation<any, I.IRequestUpdateClient>({
      query: ({ id, body }) => ({
        url: `/clients/${id}`,
        body,
        method: 'PUT',
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
        return data;
      },
      invalidatesTags: ['Clients'],
    }),
    deleteClient: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/client/delete/',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['Clients'],
    }),
    postSMSClients: builder.mutation({
      query: (params) => ({
        url: 'client/sendSms',
        method: 'POST',
        body: params,
      }),
    }),
    // deleteClient: builder.mutation<any, {id:number}>({
    // 	query: ({ id }) => ({
    // 		url: `/clients/${id}`,
    // 		method: 'DELETE',
    // 	}),
    // 	transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
    // 		return data
    // 	},
    // }),
    getClientGroupsCount: builder.query<I.IClientGroupsCountResponse, unknown>({
      query: (params: I.IGetClientGroupsParams) => ({
        url: '/clientGroups/count',
        params,
      }),
      providesTags: ['ClientGroups', 'Clients'],
    }),
  }),
});

export const {
  useGetClientGroupsQuery,
  useCreateClientGroupMutation,
  useUpdateClientGroupMutation,
  useDeleteClientGroupMutation,
  useGetClientListQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  usePostSMSClientsMutation,
  useGetClientGroupsCountQuery,
} = clientsApi;
