import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQueryForGoLang } from 'src/utils/baseQuery';
import { GoPagList } from 'src/models/types';
import { AutomationData, Cashier } from 'src/models/settings/settings';

export const checkboxSlice = createApi({
  reducerPath: 'checkbox',
  baseQuery: getBaseQueryForGoLang,
  tagTypes: ['Checkbox'],
  endpoints: (builder) => ({
    getCashiers: builder.query<GoPagList<Cashier>, any>({
      query: () => ({
        url: '/api/checkbox/cashiers/list',
        method: 'GET',
      }),
      providesTags: ['Checkbox'],
    }),
    postCashiers: builder.mutation<any, any>({
      query: (params) => ({
        url: '/api/checkbox/cashiers/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Checkbox'],
    }),
    getAutomatin: builder.query<AutomationData[], any>({
      query: () => ({
        url: '/api/checkbox/settings',
        method: 'GET',
      }),
      providesTags: ['Checkbox'],
    }),
    postAutomation: builder.mutation<any, any>({
      query: (params) => ({
        url: '/api/checkbox/settings/',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Checkbox'],
    }),
  }),
});

export const { useGetCashiersQuery, usePostCashiersMutation, useGetAutomatinQuery, usePostAutomationMutation } = checkboxSlice;
