import { useTranslation } from 'react-i18next';
import {
  EnumCurrenciesTypes,
  EnumDeliveryTypes,
  EnumLanguage,
  EnumPaymentTypes,
  EnumServiceTypes,
  EnumSmsStatuses,
  EnumTransliteType,
  EnumUPServiceTypes,
  NPPaymentType,
  PayerEnum,
  PaymentType,
} from 'src/models/enums';
import { EnumHistoryTypes } from 'src/models/settings/histories';

const useEnumTranslate = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'enums-translate',
  });
  const smsStatusesMap: {
    [key: string]: string;
  } = {
    [EnumSmsStatuses.DELIVRD]: t('status-DELIVRD'),
    [EnumSmsStatuses.EXPIRED]: t('status-EXPIRED'),
    [EnumSmsStatuses.UNDELIV]: t('status-UNDELIV'),
    [EnumSmsStatuses.REJECTD]: t('status-REJECTD'),
  };
  const transliteTypeMap: {
    [key: string]: string;
  } = {
    [EnumTransliteType.LATIN]: t('LATIN'),
    [EnumTransliteType.CYRILLIC]: t('CYRILLIC'),
  };
  const transliteTypeOptions = [
    {
      title: transliteTypeMap[EnumTransliteType.LATIN],
      value: EnumTransliteType.LATIN,
    },
    {
      title: transliteTypeMap[EnumTransliteType.CYRILLIC],
      value: EnumTransliteType.CYRILLIC,
    },
  ];
  const deliveryTypesMap: {
    [key: string]: string;
  } = {
    [EnumDeliveryTypes.NOVA_POSHTA]: t('NOVA_POSHTA'),
    [EnumDeliveryTypes.UKR_POSHTA]: t('UKR_POSHTA'),
    [EnumDeliveryTypes.SELF_DELIVERY]: t('SELF_DELIVERY'),
    [EnumDeliveryTypes.COURIER]: t('COURIER'),
  };
  const currenciesTypesMap: {
    [key: string]: string;
  } = {
    [EnumCurrenciesTypes.UAH]: t('uah'),
    [EnumCurrenciesTypes.USD]: t('usd'),
    [EnumCurrenciesTypes.EUR]: t('eur'),
  };
  const paymentTypesMap = {
    [EnumPaymentTypes.AtDelivery]: t('AtDelivery'),
    [EnumPaymentTypes.PrePayment]: t('PrePayment'),
    [EnumPaymentTypes.Paid]: t('Paid'),
    [EnumPaymentTypes.Exchange]: t('Exchange'),
  };
  const serviceTypesMap = {
    [EnumServiceTypes.Doors]: t('Doors'),
    [EnumUPServiceTypes.D]: t('Doors'),
    [EnumServiceTypes.Warehouse]: t('Warehouse'),
    [EnumUPServiceTypes.W]: t('Warehouse'),
  };
  const serviceTypesList = [
    {
      name: serviceTypesMap[EnumServiceTypes.Doors],
      value: EnumServiceTypes.Doors,
    },
    {
      name: serviceTypesMap[EnumServiceTypes.Warehouse],
      value: EnumServiceTypes.Warehouse,
    },
  ];
  const serviceTypesUPList = [
    {
      name: serviceTypesMap[EnumUPServiceTypes.D],
      value: EnumUPServiceTypes.D,
    },
    {
      name: serviceTypesMap[EnumUPServiceTypes.W],
      value: EnumUPServiceTypes.W,
    },
  ];
  const paymentTypeList = [
    { name: t('Cash'), value: PaymentType.Cash },
    { name: t('NonCash'), value: PaymentType.NonCash },
  ];
  const paymentTypeNPList = [
    { name: t('backward'), value: NPPaymentType.Backward },
    { name: t('nonBackward'), value: NPPaymentType.NonBackward },
  ];
  const historyTypesMap = {
    [EnumHistoryTypes.created]: t('created'),
    [EnumHistoryTypes.read]: t('read'),
  };
  const languageTypesMap = {
    [EnumLanguage.uk]: t('uk'),
    [EnumLanguage.ru]: t('ru'),
    [EnumLanguage.ua]: t('ua'),
    // [EnumLanguage.pl]: 'Польский',
  };
  const partyRoleList = [
    { title: t('sender'), value: PayerEnum.Sender },
    { title: t('recipient'), value: PayerEnum.Recipient },
  ];
  const partyRoleMap = {
    [PayerEnum.Sender]: t('sender'),
    [PayerEnum.Recipient]: t('recipient'),
  };

  return {
    smsStatusesMap,
    partyRoleMap,
    transliteTypeMap,
    transliteTypeOptions,
    deliveryTypesMap,
    paymentTypesMap,
    serviceTypesList,
    paymentTypeList,
    historyTypesMap,
    languageTypesMap,
    partyRoleList,
    serviceTypesUPList,
    currenciesTypesMap,
    paymentTypeNPList,
  };
};
export default useEnumTranslate;
