// import { IClientGroupsResponse } from './../api/clients/clients';
import { createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../../models/movement';
import { baseQuery } from '../../utils/baseQuery';
import { PagListResult } from '../../models/types';

export const movementApi = createApi({
  reducerPath: 'orderMovement',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['orderMovement'],
  endpoints: (builder) => ({
    getMovement: builder.query<PagListResult<I.IMovementItem>, any>({
      query: (params) => ({ url: '/movements/', params }),
    }),
  }),
});

export const { useGetMovementQuery } = movementApi;
