// import { IClientGroupsResponse } from './../api/clients/clients';
import {createApi } from '@reduxjs/toolkit/query/react';
import * as I from '../models/partners';
import { baseQuery } from '../utils/baseQuery';
import {PagListResult} from "../models/types";

export const partnersApi = createApi({
  reducerPath: 'partnersAPI',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: ['Partners'],
  endpoints: builder => ({
    getPartnersList: builder.query<PagListResult<I.IPartnersListItem>, any>({
    	query: () => ({ url: `/partners/`}),
    }),
  }),
});

export const {
    useGetPartnersListQuery,
} = partnersApi;
