import { useEffect } from 'react';
import { setPermissions, setUser } from 'src/redux/Auth';
import { toast } from 'react-toastify';
import { PAGES_ENUM } from 'src/routes';
import { useTypedDispatch } from '../useTypedDispatch';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../useTypedSelector';
import selectors from 'src/redux/selectors';
import { getAuthTokens } from 'src/utils/helpers/authHelper';
import { useTranslation } from 'react-i18next';
import { useLazyGetUserPermissionsQuery } from 'src/services/settings/goPermissions';
import {
  EventKeysEnum,
  FatalError,
  RetriableError,
  useGetUserQuery,
} from 'src/services/users/goUsers';
import 'dayjs/locale/uk';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { handleLogout } from '../../redux/slices/notificationsSlice';
import {
  onOrderClose,
  onOrderCreate,
  onOrderOpen,
} from '../../redux/slices/ordersSlce';

const useUserInfo = () => {
  const { i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { data, isError } = useGetUserQuery({});
  const userId = useTypedSelector(selectors.getUserId);
  const [getUserPermissions] = useLazyGetUserPermissionsQuery();
  const access_token = getAuthTokens().accessToken;
  const getPermissions = async (id: number) => {
    await getUserPermissions(id)
      .unwrap()
      .then((res) => dispatch(setPermissions(res)))
      .catch((e) => toast.error(e?.data?.message || 'getting roles error'));
  };

  const getUserInfo = async () => {
    if (userId) {
      return await getPermissions(userId);
    }
    if (isError && !userId) {
      return navigate(PAGES_ENUM.SESSION_OVER);
    }
    if (access_token && data?.data?.id) {
      dispatch(setUser(data?.data || null));
      void getPermissions(data?.data?.id);
      const language = data?.data?.crm_language || 'uk';
      dayjs.locale(language);
      return void i18n.changeLanguage(language);
    }
  };

  useEffect(() => {
    void getUserInfo();
  }, [access_token, data, isError]);

  useEffect(() => {
    if (data?.data?.id) {
      const language = data?.data?.crm_language || 'uk';
      dayjs.locale(language);
      void i18n.changeLanguage(language);
    }
  }, [data]);

  useEffect(() => {
    if (data?.data?.id) {
      void fetchEventSource(
        process.env.NODE_ENV !== 'development'
          ? `https://live${process.env.REACT_APP_DOMAIN}/crm/${window.location.hostname.split('.')[0]}/${data.data.id}/`
          : `https://live.promt-mkt.com/crm/${process.env.REACT_APP_DEV_TENANT}/1/`,
        {
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return;
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onmessage(ev) {
            if (ev.data && ev.event === EventKeysEnum.UserLogin) {
              return dispatch(handleLogout());
            }
          },
          onclose() {
            throw new RetriableError();
          },
          onerror(err) {
            // temporary to
            console.log('SSE onerror', err);
            if (err instanceof FatalError) {
              throw err;
            }
          },
        }
      );
      void fetchEventSource(
        process.env.NODE_ENV !== 'development'
          ? `https://open-api${process.env.REACT_APP_DOMAIN}/crm/${window.location.hostname.split('.')[0]}/`
          : `https://open-api.promt-mkt.com/crm/${process.env.REACT_APP_DEV_TENANT}/`,
        {
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return;
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onmessage(ev) {
            if (ev.data && ev.event === EventKeysEnum.NewOrder) {
              return dispatch(onOrderCreate(JSON.parse(ev.data).data));
            }

            if (ev.data && ev.event === EventKeysEnum.OpenOrder) {
              if (data?.data.id === JSON.parse(ev.data).data.user_id) return;
              return dispatch(onOrderOpen(JSON.parse(ev.data).data));
            }
            if (ev.data && ev.event === EventKeysEnum.CloseOrder) {
              if (data?.data.id === JSON.parse(ev.data).data.user_id) return;
              return dispatch(onOrderClose(JSON.parse(ev.data).data));
            }
          },
          onclose() {
            throw new RetriableError();
          },
          onerror(err) {
            // temporary to
            console.log('SSE onerror', err);
            if (err instanceof FatalError) {
              throw err;
            }
          },
        }
      );
    }
  }, [data?.data?.id]);
};

export default useUserInfo;
